// eslint-disable-next-line import/prefer-default-export
export const boxQuery = `
  query box {
    box {
      _id
      createdAt
      userId
      checkoutId
      pagoServicioId
      envioBoxId
      items {
        ... on ItemOnline {
          _id
          descripcionVenta
          precioVenta
          marca
          categoria
          imagenes {
            secureUrl
          }
          talla
          status
          universo
          proyecto
        }
        ... on ItemOnlineInfante {
          _id
          descripcionVenta
          precioVenta
          marca
          categoriaInfante: categoria
          imagenes {
            secureUrl
          }
          tallas
          status
          estado
          genero
          universo
          proyecto
        }
      }
      envioBox {
        tipo
        direccion {
          nombres
          apellidos
          numeroDocumento
          telefono1
          telefono2
          direccion
          referencia
          departamento
          provincia
          distrito
        }
      }
    }
  }
`;
