export const userCurrentQuery = `
  {
    userCurrent {
      _id
      username
      email
      createdAt
      datos {
        nombres
        apellidos
        numeroDocumento
        telefono1
        telefono2
      }
      direccion {
        direccion
        referencia
        departamento
        provincia
        distrito
      }
      cognitoSub
      intercomHash
    }
    carrito {
      _id
      createdAt
      items {
        ... on ItemOnline {
          _id
          descripcionVenta
          precioVenta
          marca
          categoria
          imagenes {
            secureUrl
          }
          talla
          status
          universo
          proyecto
        }
        ... on ItemOnlineInfante {
          _id
          descripcionVenta
          precioVenta
          marca
          categoriaInfante: categoria
          imagenes {
            secureUrl
          }
          tallas
          status
          estado
          genero
          universo
          proyecto
        }
      }
      checkoutId
      userId
    }
  }
`;

export const puntosHistorialQuery = `query PuntosHistorial($userId: ID, $filter: PuntosFilterInput) {
  puntosHistorial(userId: $userId, filter: $filter) {
    montoActual
    historial {
      fecha
      montoOriginal
      montoActual
      validoHasta
    }
  }
}
`;

export const userPerfilQuery = `
  query userPerfil($userId: ID) {
    puntosHistorial(userId: $userId) {
      montoActual
      historial {
        fecha
        montoOriginal
        montoActual
        validoHasta
      }
    }
    ventasOnline {
      _id
      status
      envioId
      envio {
        _id
        status
        tipo
      }
      pagoId
      pago {
        _id
        status
        tipo
      }
      fecha
      monto {
        total
        puntos
        tarjetaOnline
        deposito
        descuento
        envio
        costoDeposito
        notasDeCredito
      }
      items {
        ... on ItemOnline {
          _id
          descripcionVenta
          precioVenta
          marca
          categoria
          imagenes {
            secureUrl
          }
          talla
          status
          universo
          proyecto
        }
        ... on ItemOnlineInfante {
          _id
          descripcionVenta
          precioVenta
          marca
          categoriaInfante: categoria
          imagenes {
            secureUrl
          }
          tallas
          status
          estado
          genero
          universo
          proyecto
        }
      }
      boxId
    }
  }
`;

export const userPerfilVentasQuery = `
  query userPerfil {
    ventasOnline {
      _id
      pago {
        _id
        status
      }
      boxId
    }
  }
`;
