import React, { useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

import { useDispatch } from 'react-redux';

import { identifyUser } from '../../../lib/utils/analytics';
import { fetcher } from '../../../lib/swr';
import sliceApp from '../../../lib/reducers/app';
import sliceUser from '../../../lib/reducers/user';
import sliceCarrito from '../../../lib/reducers/carrito';
import sliceBox from '../../../lib/reducers/box';
import { userCurrentQuery } from '../../../lib/queries/user';
import { boxQuery } from '../../../lib/queries/box';

const AuthContext = React.createContext();

const {
  actions: { errorHandle },
} = sliceApp;
const {
  actions: { setUserData },
} = sliceUser;
const {
  actions: { setNewBox, setLoading: setLoadingBox },
} = sliceBox;
const {
  actions: { setNewCarrito, resetCarrito, setLoading: setCarritoLoading },
} = sliceCarrito;

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [value, setValue] = useState({ user: null, loading: true });
  const dispatch = useDispatch();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const onAuthChange = ({ channel }) => {
      if (channel === 'auth') {
        Auth.currentAuthenticatedUser()
          .then((userRes) => {
            if (userRes) {
              setValue({ user: userRes, loading: false });
              fetcher({
                query: userCurrentQuery,
              }).then(({ userCurrent, carrito }) => {
                const {
                  cognitoSub,
                  intercomHash,
                  email,
                  nombres,
                  apellidos,
                  _id: userId,
                } = userCurrent;

                identifyUser({
                  cognitoSub,
                  intercomHash,
                  email,
                  displayName: `${nombres} ${apellidos}`,
                });

                dispatch([
                  setUserData(userCurrent),
                  setNewCarrito({ ...carrito }),
                  setCarritoLoading(false),
                ]);

                fetcher({
                  query: boxQuery,
                  variables: { userId },
                }).then(({ box }) => {
                  if (box) {
                    dispatch([setNewBox(box), setLoadingBox(false)]);
                  }
                }).catch((e) => {
                  dispatch(errorHandle(e));
                });
              }).catch((e) => {
                dispatch(errorHandle(e));
              });
            } else {
              setValue({ user: null, loading: false });

              dispatch([setUserData(null), resetCarrito()]);
            }
          }).catch(() => {
            setValue({ user: null, loading: false });
            dispatch([setUserData(null), resetCarrito()]);
          });
      } else {
        setValue({ user: null, loading: false });
      }
    };
    onAuthChange({ channel: 'auth' });
    Hub.listen('auth', onAuthChange);
    return () => {
      Hub.remove('auth', onAuthChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
